import React from 'react';
import '../css/MainWindow.css';
import '../css/Articles.css';
import { Link } from 'react-router-dom';

const TechnikInformatykPage = () => {
  

  return (
    <div id="container">
      <div id="banner">
        <h1>Technik informatyk</h1>
      </div>
      
      <div id="article-wrapper">
      <p>Absolwent szkoły dającej wykształcenie średnie, który zdobył kwalifikację <b>INF.02. – Administracja i eksploatacja systemów komputerowych, urządzeń peryferyjnych i lokalnych sieci komputerowych</b> po uzupełnieniu kwalifikacji <b>INF.03. Tworzenie i administrowanie stronami i aplikacjami internetowymi oraz bazami danych</b> zdobywa zawód <b>technik  informatyk.</b></p>
      <Link to="/technik-informatyk/sso">
            <div className='article-box'>
                <img className="article-box-thumbnail" src="https://cdn.sjarecki.pl/sso-ppo/sso.webp" alt=''/>
                <h2>Sieciowe Systemy Operacyjne</h2>
                <h4>Windows | Linux</h4>
            </div>
        </Link>
        <Link to="/technik-informatyk/wia">
            <div className='article-box'>
                <img className="article-box-thumbnail" src="https://cdn.sjarecki.pl/sso-ppo/wia.webp" alt=''/>
                <h2>Witryny i aplikacje internetowe</h2>
                <h4>HTML | CSS | JavaScript | PHP</h4>
            </div>
        </Link>
      </div>
    </div>
  );
};

export default TechnikInformatykPage;
