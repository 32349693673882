import React from 'react';
import '../css/Footer.css';

const Footer = () => {

  return (
    <footer id='footer'>
        &copy; 2024, Szymon Jarecki<br/>
        <span className='disclaimer'>Wszystkie ikony widoczne na stronie są z biblioteki <a target='_blank' href='https://fontawesome.com/'>FontAwesome</a></span>
    </footer>
  )
};

export default Footer;
