import React, { useState, useEffect } from 'react';
import '../css/ResultImage.css';

const ResultImage = ({ imgPath, desc, video=false }) => {
  const [isEnlarged, setIsEnlarged] = useState(false);

  const handleImageClick = () => {
    setIsEnlarged(!isEnlarged);
  };

  useEffect(() => {
    if (isEnlarged) {
        document.body.classList.add('no-scroll');
        } else {
        document.body.classList.remove('no-scroll');
        }

        // Cleanup function to remove the class when component unmounts
        return () => {
        document.body.classList.remove('no-scroll');
        };
    }, [isEnlarged]);

  return (
    <>
      <div style={{overflow:'hidden',display:'block',width:'fit-content'}}>
          {!video ? (
            <img
          className='result-image'
          alt={imgPath}
          src={'https://cdn.sjarecki.pl/sso-ppo/' + imgPath}
          onClick={handleImageClick}
          />) 
          : (
            <video 
            className='result-image' 
            controls="" 
            onClick={handleImageClick}
            autoPlay muted loop>
              <source 
              src={'https://cdn.sjarecki.pl/sso-ppo/' + imgPath} 
              type="video/webm"/>
              Twoja przeglądarka nie wspiera tagu video.
            </video>
          )}
      </div>
      <p className='description'>{desc}</p>
        <div onClick={handleImageClick} className={`image-fullscreen-wrapper ${isEnlarged ? `active` : `disabled`}`}>
            {!video ? (
            <img
            className={`image-fullscreen`}
            alt={imgPath}
            src={'https://cdn.sjarecki.pl/sso-ppo/' + imgPath}
            onDragStart={(e) => e.preventDefault()}
            />
          ) : (
            <video 
            className={`image-fullscreen`}
            controls="" 
            autoPlay muted loop>
              <source 
              src={'https://cdn.sjarecki.pl/sso-ppo/' + imgPath} 
              type="video/webm"/>
              Twoja przeglądarka nie wspiera tagu video.
            </video>
          )}

          <p className='description' style={{textAlign:'center'}}>{desc}</p>
        </div>
    </>
  );
};

export default ResultImage;
