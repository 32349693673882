import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { faWindows } from "@fortawesome/free-brands-svg-icons";

const KeyShortcut = ({ children, windows=false, up=false, down=false }) => {

    const style = {
        backgroundColor: "#444444",
        fontFamily: '"Source Code Pro", monospace',
        padding: '0 5px',
        borderRadius: '10px',
        whiteSpace: 'break-spaces',
        fontSize: '12pt',
        minWidth: '12pt',
        display: 'inline-block',
        textAlign: 'center'
    }

    return (
        <code style={style}>
            {windows ? (
                <FontAwesomeIcon icon={faWindows} />
            ) : up ? (
                <FontAwesomeIcon icon={faCaretUp} />
            ) : down ? (
                <FontAwesomeIcon icon={faCaretDown} />
            ) : (
                children
            )}
        </code>
    );
};

export default KeyShortcut;
