import React, { useState, useEffect } from 'react';
import '../css/NavBar.css';
import { Link } from 'react-router-dom';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MobileNav = ({ currentPath }) => {
  const [isOpen, setIsOpen] = useState(false);
  const root = document.getElementsByTagName( 'html' )[0];

  const toggleDiv = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      root.classList.add('no-scroll');
    } else {
      root.classList.remove('no-scroll');
    }

    // Cleanup function to remove the class when component unmounts
    return () => {
      root.classList.remove('no-scroll');
    };
  }, [isOpen]);

  return (
    <>
      <nav id="mobile-nav">
        <div className='flex-wrapper'>
          <div id="logo">Szymon Jarecki</div>
          <button id="menu-button" onClick={toggleDiv}>
            <FontAwesomeIcon
              icon={faBars}
            />
          </button>
        </div>
      </nav>
      <div id="mobile-links-wrapper" style={{
        display: isOpen ? 'block' : 'none',
      }}>
        <button id="close-button" onClick={toggleDiv}>
          <FontAwesomeIcon
            icon={faXmark}
          />
        </button>
        <ul id="links-mobile">
          <li onClick={toggleDiv} className={currentPath === '/' ? 'active' : ''}><Link to="/">Home</Link></li>
          <li onClick={toggleDiv} className={/^\/technik-informatyk/.test(currentPath) ? 'active' : ''}>
            <Link to="/technik-informatyk">Technik informatyk</Link>
          </li>
          <li>Technik Programista</li>
        </ul>
      </div>
    </>
  );
};

export default MobileNav;
