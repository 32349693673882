import React from "react";

const Command = ({ children }) => {

    const style = {
        backgroundColor: "#55555555",
        fontFamily: '"Source Code Pro", monospace',
        padding: '2px 5px',
        borderRadius: '5px',
        whiteSpace: 'break-spaces',
    }

    return (
        <code style={style}>
            {children}
        </code>
    );
};

export default Command;
