import React from 'react';
import '../css/MainWindow.css';
import '../css/Articles.css';
import Collapsible from '../components/Collapsible';
import Command from "../components/Command";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowTurnUp } from '@fortawesome/free-solid-svg-icons';
import ResultImage from '../components/ResultImage';

const WIA = () => {
      
    return (
    <div id="container">
        <div id="banner" style={{height:'100px',alignItems:'flex-start',padding:'0 60px'}}>
            <h1>Witryny i aplikacje internetowe</h1>
        </div>
      
        <div id="article-wrapper" className='column'>
            <h1>JavaScript</h1>
            <Collapsible title='Metoda window.open'>
                <p><Command>window.open</Command> to metoda w JavaScript, która umożliwia otwieranie nowych okien lub zakładek w przeglądarce. Można jej używać do otwierania URL lub pliku HTML w nowym oknie lub karcie, a także do definiowania jego rozmiaru i pozycji. Oto podstawowe informacje na temat jej działania:</p>
                <SyntaxHighlighter language='javascript' style={vs2015}>
                    window.open(url, target, windowFeatures);
                </SyntaxHighlighter>
                <h3>Parametry</h3>
                <ol>
                    <li><b>url</b> (string): Adres URL, który ma być otwarty. Może to być pełny adres (np. <Command>https://example.com</Command>) lub ścieżka względna.</li>
                    <li><b>target</b> (string): Docelowy kontekst, w którym ma być otwarty URL. Może to być:
                        <ul>
                        <li><Command>_blank</Command>: Otwiera nową stronę w nowym oknie lub karcie.</li>
                        </ul>
                    </li>
                    <li><b>specs</b> (string): Opcje konfiguracyjne dla nowego okna, takie jak:
                        <ul>
                        <li><Command>width</Command>: szerokość okna</li>
                        <li><Command>height</Command>: wysokość okna</li>
                        <li><Command>top</Command>: odległość od górnej krawędzi ekranu</li>
                        <li><Command>left</Command>: odległość od lewej krawędzi ekranu</li>
                        <li><Command>popup</Command>: określa czy ma otworzyć nową kartę czy nowe okno</li>
                        </ul>
                    </li>
                </ol>
            </Collapsible>
        </div>
    </div>
  );
};

export default WIA;
