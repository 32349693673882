import React from 'react';
import '../css/RedirectButton.css';
import { Link } from 'react-router-dom';

const RedirectButton = ({buttonText, buttonURL}) => {
  return (
    <Link className='redirect-button' to={buttonURL}>{buttonText}</Link>
  )
};

export default RedirectButton;
