import './App.css';
import MainWindow from './components/MainWindow.jsx';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import NavBar from './components/NavBar.jsx';
import MobileNav from './components/MobileNav.jsx';
import Footer from './components/Footer.jsx';
import TechnikInformatykPage from './pages/TechnikInformatykPage.jsx';
import './css/Main.css';
import SSO from './pages/SSO.jsx';
import WIA from './pages/WIA.jsx';
import {BrowserView, MobileView} from 'react-device-detect';
import { useEffect } from 'react';

function AppContent() {
  const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

  const location = useLocation();
  return (
    <>
      <BrowserView>
        <NavBar currentPath={location.pathname}/>
      </BrowserView>
      <MobileView>
        <MobileNav currentPath={location.pathname}/>
      </MobileView>
      <Routes>
        <Route path="/" element={<MainWindow />} />
        <Route path="/technik-informatyk" element={<TechnikInformatykPage />} />
        <Route path="/technik-informatyk/sso" element={<SSO />} />
        <Route path="/technik-informatyk/wia" element={<WIA />} />
      </Routes>
      <Footer />
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
}

export default App;
