import React from 'react';
import '../css/MainWindow.css';
import RedirectButton from './RedirectButton';

const MainWindow = () => {
  

  return (
    <div id="container">
      <div id="banner">
        <h2>Materiały dla <b>technika informatyka</b> oraz <b>technika programisty</b>.</h2>
        <div>
          {/* <RedirectButton buttonText="Technik informatyk" buttonURL='/technik-informatyk'/>
          <RedirectButton buttonText="Technik programista" buttonURL="/technik-programista"/> */}
        </div>
      </div>
      <div className='article'>
        <img src="https://cdn.sjarecki.pl/sso-ppo/chomiczek-it.webp" style={{width:'564px'}} alt=''/>
        <div className='text-content'>
          <h1>Technik Informatyk</h1>
          <p>Materiały dla zawodu <b>Technik informatyk</b> skupiają się głównie na kwalifikacji zawodowej <b>INF.02</b> oraz częściowo na kwalifikacji <b>INF.03</b> i obejmują zagadnienia takie jak:
          <ul>
            <li>Witryny i aplikacje internetowe:
              <ul>
                <li>JavaScript - skrypty, które wchodza w interakcje ze stroną internetową</li>
                <li>PHP - łączenie z bazą danych, wyświetlanie danych z bazy oraz tworzenie formularzy dodających / edytujących dane w bazie danych</li>
              </ul>
            </li>
            <li>Sieciowe systemy operacyjne - konfiguracja systemów: 
              <ul>
                <li>Windows - zarządzanie użytkownikami, konfiguracja systemu poprzez przystawki, obsługa i zarządzanie systemem poprzez Wiersz Poleceń <b>CMD</b>, tworzenie porstych skryptów <b>batch</b>, zarządzanie dyskami oraz przydziałami, systemy liczbowe oraz operacje arytmetyczne na systemie binarnym. Ponadto podstawowe strategie tworzenia backupów, budowa logiczna oraz fizyczna dysku, poznanie struktury systemów plików oraz różnice między systemami plików <b>FAT</b> oraz <b>NTFS</b>.</li>
                <li>Linux - zapoznanie się z filozofią systemów operacyjnych z rodziny Linux - dystrybucje. Obsługa systemu poprzez terminal, zarządzanie użytkownikami oraz grupami, zarządzanie uprawnieniami, poznanie systemów plików <b>EXT</b>.</li>
              </ul>
            </li>
          </ul>
          I nie tylko :)
          </p>
        </div>
      </div>
      <div className='article'>
      <img src="https://cdn.sjarecki.pl/sso-ppo/chomiczek-programista.webp" style={{width:'564px'}} alt=''/>
        <div className='text-content'>
          <h1>Technik Programista</h1>
          <p>Materiały dla zawodu <b>Technik programista</b> obejmują przedmioty i zagadnienia takie jak:
          <ul>
            <li>Projektowanie oprogramowania - system kontorli wersji <b>git</b>, diagramy UML, wzorce projektowe, diagram Gantta, metodyki oraz metodologie związane z tworzeniem oprogramowania.</li>
          </ul>
          </p>
        </div>
      </div>
    </div>
  );
};

export default MainWindow;
