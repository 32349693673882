import React from 'react';
import '../css/NavBar.css';
import { Link } from 'react-router-dom';

const NavBar = ({ currentPath }) => {
  return (
    <nav id="desktop-nav">
      <div id="logo">Szymon Jarecki</div>
      <ul id="links">
        <li className={currentPath === '/' ? 'active' : ''}><Link to="/">Home</Link></li>
        <li className={/^\/technik-informatyk/.test(currentPath) ? 'active' : ''}>
          <Link to="/technik-informatyk">Technik informatyk</Link>
        </li>
        <li>Technik Programista</li>
      </ul>
    </nav>
  );
};

export default NavBar;
